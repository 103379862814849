<template>
    <section class="content">
        <div class="row mb-3">
            <div class="col-auto ml-auto">
                <button
                    type="button"
                    v-on:click="createForm"
                    class="btn btn-primary"
                >
                    <i class="fa fa-plus"></i> Buat Notifikasi
                </button>
            </div>
        </div>
        <table class="table table-hover" ref="tableblastnotif">
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Judul</th>
                    <th>Pesan</th>
                    <th>Url Gambar (opsional)</th>
                    <th>Tag</th>
                    <th>Status</th>
                    <th>Dikirim Setelah</th>
                </tr>
            </thead>
            <tbody></tbody>
        </table>
        <div
            class="modal"
            tabindex="-1"
            role="dialog"
            ref="formDialog"
            data-backdrop="static"
        >
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <form role="form" @submit.prevent="submitForm">
                        <div class="modal-header border-bottom-0">
                            <h4 class="modal-title mx-auto mt-4 font-weight-bold">Sent Blast Notification</h4>
                            <button
                                type="button"
                                class="close ml-0"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body mx-3">
                            <p v-if="errors.length" class="alert alert-danger alert-dismissible fade show" role="alert">
                                <b>Please correct the following error(s):</b>
                                <ul>
                                    <li v-for="error in errors" v-bind:key="error">{{ error }}</li>
                                </ul>
                                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </p>
                            <div class="overlay d-flex justify-content-center align-items-center" v-if="loadingContent"><h1 style="font-size:40px;">Sedang diproses <i class="fas fa-sync-alt fa-spin"></i></h1></div>
                            <div class="row">
                                <div class="col-md-12 form-group">
                                    <label class="font-weight-normal">Judul</label>
                                    <input required id="title" class="form-control" v-model="form.title" type="text" name="title" placeholder="Tambahkan judul notifikasi" :disabled="og_status == 1" 
                                    onkeydown="return (event.ctrlKey || event.altKey 
                                    || (47<event.keyCode && event.keyCode<58 && event.shiftKey==false)
                                    || (95<event.keyCode && event.keyCode<106)|| (event.keyCode==8) || (event.keyCode==9) 
                                    || (34<event.keyCode && event.keyCode<40) || (event.keyCode==32) || (event.keyCode==46) || (event.keyCode==13) || (64<event.keyCode && event.keyCode<91))"/>
                                </div>
                                <div class="col-md-12 form-group">
                                    <label class="font-weight-normal">Pesan</label>
                                    <!-- <input required id="message" class="form-control" v-model="form.message" type="text" name="message" placeholder="Tambahkan pesan notifikasi" :disabled="og_status == 1" /> -->
                                    <textarea required id="message" class="form-control" v-model="form.message" type="text" name="message" placeholder="Tambahkan pesan notifikasi" :disabled="og_status == 1" autocomplete="off" 
                                    onkeydown="return (event.ctrlKey || event.altKey 
                                    || (47<event.keyCode && event.keyCode<58 && event.shiftKey==false) || (event.keyCode==57  && event.shiftKey==true)
                                    || (95<event.keyCode && event.keyCode<106)|| (event.keyCode==8) || (event.keyCode==9) || (event.keyCode==48  && event.shiftKey==true)
                                    || (event.keyCode==190  && event.shiftKey==false) || (event.keyCode==188  && event.shiftKey==false)
                                    || (34<event.keyCode && event.keyCode<40) || (event.keyCode==32) || (event.keyCode==46) || (event.keyCode==13) || (64<event.keyCode && event.keyCode<91))"></textarea>
                                </div>
                                <div class="col-md-12 form-group">
                                    <label class="font-weight-normal">Gambar</label>
                                    <input  id="image" class="form-control button_img" :style="cssVars" v-on:input="event => setBg(event)" v-model="form.image" type="text" name="image" placeholder="URL Gambar" />
                                    <small class="form-text text-muted">Url : https://api.boscod.com:3001/favicon.ico</small>
                                </div>
                                <div class="col-md-12 form-group">
                                    <label class="font-weight-normal">Content Berita</label>
                                    <select class="form-control w-100" v-model="form.content_id">
                                        <option value="#" disabled selected>Pilih Content</option>
                                        <option v-for="conten in wpContent" v-bind:key="conten.id" v-bind:value="conten.id">
                                                {{ conten.title }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-md-12 form-group">
                                    <label class="font-weight-normal">Jadwal Pengiriman</label>
                                    <label v-for="(item,index) in schedule"  v-bind:key="index" class="d-flex align-items-center font-weight-normal" style="gap: 5px">
                                        <input name="myfield" type="radio" v-bind:checked="item.val == set_schedule" @change="handleRadio(item.val)">
                                        {{item.txt}}
                                    </label>
                                    <input :min="datetime_min" v-if="set_schedule=='set'" style="margin-top:12px" id="send_after"  class="form-control" v-model="form.send_after" type="datetime-local" name="send_after"/>
                                </div>
                                <div class="col-md-12 form-group">
                                    <label class="font-weight-normal">Tag</label><br>
                                    <label v-for="(tag,index) in tags"  v-bind:key="index" class="form-check form-check-inline border border-secondary rounded p-1" style="gap: 5px">
                                        <input class="form-check-input border border-secondary" type="checkbox" v-bind:checked="tag.selected" @change="handleCheckbox(index)">
                                        {{tag.txt}}
                                    </label>
                                </div>
                                <div class="col-sm-6 mt-2">
                                    <button type="submit" value="draft" class="btn btn-block btn-outline-primary font-weight-bold" :disabled="(loading)">
                                        Simpan ke draft <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    </button>
                                </div>
                                <div class="col-sm mt-2">
                                    <button type="submit" value="submit" class="btn btn-block btn-primary font-weight-bold" :disabled="(loading)">
                                        Kirim <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div
            class="modal"
            tabindex="-1"
            role="dialog"
            ref="formEditDialog"
            data-backdrop="static"
        >
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <form role="form" @submit.prevent="submitForm">
                        <div class="modal-header border-bottom-0">
                            <h4 class="modal-title mx-auto mt-4 font-weight-bold">Edit Blast Notification</h4>
                            <button
                                type="button"
                                class="close ml-0"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body mx-3">
                            <p v-if="errors.length" class="alert alert-danger alert-dismissible fade show" role="alert">
                                <b>Please correct the following error(s):</b>
                                <ul>
                                    <li v-for="error in errors" v-bind:key="error">{{ error }}</li>
                                </ul>
                                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </p>
                            <div class="overlay d-flex justify-content-center align-items-center" v-if="loadingContent"><h1 style="font-size:40px;">Sedang diproses <i class="fas fa-sync-alt fa-spin"></i></h1></div>
                            <div class="row">
                                <div class="col-md-12 form-group">
                                    <label class="font-weight-normal">Judul</label>
                                    <input required id="title" class="form-control" v-model="form.title" type="text" name="title" placeholder="Tambahkan judul notifikasi" :disabled="og_status == 1" />
                                </div>
                                <div class="col-md-12 form-group">
                                    <label class="font-weight-normal">Pesan</label>
                                    <textarea required id="message" class="form-control" v-model="form.message" type="text" name="message" placeholder="Tambahkan pesan notifikasi" :disabled="og_status == 1" autocomplete="off" />
                                </div>
                                <div class="col-md-12 form-group">
                                    <label class="font-weight-normal">Gambar</label>
                                    <input  id="image" class="form-control button_img" :style="cssVars" v-on:input="event => setBg(event)" v-model="form.image" type="text" name="image" placeholder="URL Gambar" />
                                    <small class="form-text text-muted">Url : https://api.boscod.com:3001/favicon.ico</small>
                                </div>
                                <div class="col-md-12 form-group">
                                    <label class="font-weight-normal">Jadwal Pengiriman</label>
                                    <label v-for="(item,index) in schedule"  v-bind:key="index" class="d-flex align-items-center font-weight-normal" style="gap: 5px">
                                        <input name="myfield" type="radio" v-bind:checked="item.val == set_schedule" @change="handleRadio(item.val)">
                                        {{item.txt}}
                                    </label>
                                    <input :min="datetime_min" v-if="set_schedule=='set'" style="margin-top:12px" id="send_after"  class="form-control" v-model="form.send_after" type="datetime-local" name="send_after"/>
                                </div>
                                <div class="col-md-12 form-group">
                                    <label class="font-weight-normal">Tag : 
                                        <span v-if="showEditCheckedTag=='tester'" class="badge badge-secondary">{{ showEditCheckedTag }}</span>
                                        <span v-if="showEditCheckedTag=='authenticated'" class="badge badge-info">{{ showEditCheckedTag }}</span>
                                    </label>
                                </div>
                                <div class="col-sm-6 mt-2">
                                    <button type="submit" value="draft" class="btn btn-block btn-outline-primary font-weight-bold" :disabled="(loading)">
                                        Simpan<span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    </button>
                                </div>
                                <div class="col-sm mt-2">
                                    <button type="submit" value="submit" class="btn btn-block btn-primary font-weight-bold" :disabled="(loading)">
                                        Kirim <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
    <!-- /.content -->
</template>
<script>
import { createTable, authFetch } from "@/libs/hxcore";
import Swal from "sweetalert2";
import $ from "jquery";
import moment from "moment";
import Daterangepicker from "daterangepicker";
import "daterangepicker/daterangepicker.css";

export default {
    name: 'FormBlastNotif',
    data() {
        return {
            img_url: '',
            msg: '',
            loading: false,
            data: [],
            form: {
                image: '',
                title: '',
                message: '',
                content_id: '',
            },
            wpContent: {},
            tags: [
				{txt: 'Authenticated', val: 'authenticated',selected:false},
                {txt: 'Tester', val: 'tester',selected:true}
			],
            checkedTag: ['tester'],
            showEditCheckedTag: '',
            og_status: 0,
            method: "",
            errors: [],
            types: {
                'info'    : 'Informasi',
                'warning' : 'Pengumuman',
                'success' : 'Promosi',
                'danger'  : 'Peringatan',
            },
            disabled: true,
            file: {},
            loadingContent: '',
            dt1: moment().startOf("month"),
            dt2: moment().endOf("month"),
            set_schedule: "now",
            datetime_min: moment().format().slice(0,16),
            schedule: [
				{txt: 'Kirim sekarang', val: 'now'},
                {txt: 'Pilih waktu Pengiriman', val: 'set'}
			],
            comparisonvalue:'now',
        }
    },
    components: {},
    computed: {
        cssVars() {
            return {
                '--background':`url(${this.img_url}) no-repeat`
            }
        }
    },
    created: function() {
        this.form.tanggal = moment().format("DD/MM/YYYY");
        this.loadWpContent();
    },
    methods: {
        loadWpContent() {
            authFetch(`/dashboard/contens`).then((res) => {
                res.json().then((json) => {
                this.loading = false;
            // this.courier = json.data;
                if (json.success) {
                    this.wpContent = json.data;
                    console.log('content',wpContent);
                }
                });
            });
        },
        setBg() {
           this.img_url = this.form.image;
        },
        createForm() {
            const e = this.$refs;
            this.form = {};
            // this.form.send_after = moment().format("Y-m-d H:i:s") ;
            this.form.send_after = moment().format().slice(0,16);
            this.datetime_min = moment().format().slice(0,16);
            this.method = "POST";
            this.errors = [];
            $(e.formDialog).modal("show");
        },
        submitForm: function (ev) {
            var self = this;
            const e = this.$refs;
            var urlSubmit = "";
            if (this.method == "PUT"){
                this.form.type = ev.submitter.value;
                urlSubmit = "/report/notification/" + this.form.id;
                var data = Object.keys(self.form).map(
                (key) =>
                    encodeURIComponent(key) + "=" + encodeURIComponent(self.form[key])
                ).join("&");
                authFetch(urlSubmit, {
                    method: "PUT",
                    body: data
                })
                .then((res) => {
                    if (res.status === 201) {
                    } else if (res.status === 400) {
                    } else if (res.status === 500){
                        Swal.fire("Timeout!", ``, "error");
                        this.loadingContent = false;
                        this.loading = false;
                        this.msg = '';
                    }
                    return res.json();
                })
                .then((js) => {
                    console.log('res',typeof js.result.errors);
                    console.log('res',js.result);
                    this.loadingContent = false;
                    this.loading = false;
                    if (typeof js.result.errors == 'undefined') {
                        if (typeof js.result.success == 'undefined') {
                            Swal.fire({icon: 'success',title: ev.submitter.value == 'submit' ? 'Notifikasi terkirim.' : 'Berhasil menyimpan draft',html: '<b>Penerima : </b>'+js.result.recipients});
                            this.checkedTag = ['tester'];
                            this.tags = [
                                {txt: 'Authenticated', val: 'authenticated',selected:false},
                                {txt: 'Tester', val: 'tester',selected:true}
                            ],
                            this.form.image = "";
                            this.form.title = "";
                            this.form.message = "";
                            this.img_url = "";
                            this.msg = '';
                            this.send_after = '';
                            $(e.formEditDialog).modal("hide");
                            this.table.api().ajax.reload();
                        }else{
                            if(js.result.success){
                                Swal.fire({icon: 'success',title: ev.submitter.value == 'submit' ? 'Notifikasi terkirim.' : 'Berhasil menyimpan draft',html: '<b>Penerima : </b>'+js.result.recipients});
                                $(e.formEditDialog).modal("hide");
                                this.table.api().ajax.reload();
                            }else{
                                Swal.fire({icon: 'error',title: 'Oops...',text: js.result.message})
                            }
                        }
                        
                    } else {
                        if (typeof js.result.recipients == 'undefined') {
                            Swal.fire({icon: 'error',title: 'Oops...',text: js.result.errors})
                        }else{
                            Swal.fire({icon: 'warning',title: 'Success.',html: '<b>Penerima : </b>'+js.result.recipients+'<br><br><b>Info : </b>'+js.result.errors});
                            $(e.formEditDialog).modal("hide");
                            this.table.api().ajax.reload();
                        }
                    }
                });
            }else if(this.method == "POST"){
                urlSubmit = "/report/notification/blast_notif";
                var formData = new FormData();
                formData.append('image', this.form.image);
                if(this.set_schedule == "set"){
                    formData.append('send_after', this.form.send_after);
                }
                formData.append('title', this.form.title);
                formData.append('message', this.form.message);
                formData.append('tag', this.checkedTag);
                formData.append('type', ev.submitter.value);
                formData.append('status', ev.submitter.value == 'submit' ? 1 : 0);
                formData.append('content_id', this.form.content_id?this.form.content_id:'');
                authFetch(urlSubmit, {
                    method: "post",
                    body: formData,
                    headers: {
                    'Content-Type': null,
                    },
                })
                .then((res) => {
                    if (res.status === 201) {
                    } else if (res.status === 400) {
                    } else if (res.status === 500){
                        Swal.fire("Timeout!", ``, "error");
                        this.loadingContent = false;
                        this.loading = false;
                        this.msg = '';
                    }
                    return res.json();
                })
                .then((js) => {
                    console.log('res',typeof js.result.errors);
                    console.log('res',js.result);
                    this.loadingContent = false;
                    this.loading = false;
                    if (typeof js.result.errors == 'undefined') {
                            Swal.fire({icon: 'success',title: ev.submitter.value == 'submit' ? 'Notifikasi terkirim.' : 'Berhasil menyimpan draft',html: '<b>Penerima : </b>'+js.result.recipients});
                            this.checkedTag = ['tester'];
                            this.form.image = "";
                            this.send_after = "";
                            this.form.title = "";
                            this.form.message = "";
                            this.img_url = "";
                            this.msg = '';
                            this.table.api().ajax.reload();
                    } else {
                        if (typeof js.result.recipients == 'undefined') {
                            Swal.fire({icon: 'error',title: 'Oops...',text: js.result.errors})
                        }else{
                            this.table.api().ajax.reload();
                            Swal.fire({icon: 'warning',title: 'Success.',html: '<b>Penerima : </b>'+js.result.recipients+'<br><br><b>Info : </b>'+js.result.errors})
                        }
                    }
                });
            }
        },
		handleRadio: function (index) {
	        this.set_schedule = index;
		},handleCheckbox: function (index) {
            this.checkedTag = [];
            if(this.tags[index].selected){
                this.tags[index].selected = false
            }else if(!this.tags[index].selected){
                this.tags[index].selected = true
            }
            for (let i = 0; i < this.tags.length; ++i) {
                if(this.tags[i].selected){
                    this.checkedTag.push(this.tags[i].val)
                }
            }
            
		}
    },
    mounted() {
        const e = this.$refs;
        var self = this;
        new Daterangepicker(
            this.$refs.daterange,
            {
                // startDate: !this.dt1 ? moment().day(-31) : this.dt1,
                // endDate: !this.dt2 ? moment() : this.dt2,
                locale: {
                    format: 'DD/MM/YYYY'
                },
            },
            function (d1, d2) {
                self.start_date = d1.format("YYYY-MM-DD");
                self.end_date = d2.format("YYYY-MM-DD");
            }
        );
        this.table = createTable(e.tableblastnotif, {
            title: "",
            roles: this.$route.params.roles,
            ajax: "/report/notification?message_type=public",
            processing: true,
            language: { processing: '<i class="fa fa-spinner fa-5x fa-spin" style="font-size:50px;color:black;"></i>'},
            columns: [
                { data: "id", visible: false },
                { data: "title" },
                { data: "body" },
                { data: "link" },
                { data: "topic", render: function (data, type, row, meta) { return '<span class="badge badge-' + ((data == 'authenticated') ? 'info' : (data == 'tester') ? 'secondary' : 'light') + '">' + data + '</span>' } },
                { data: "status", render: function (data, type, row, meta) { return '<span class="badge badge-' + ((data == '1') ? 'success">Published' : 'yellow">Draft') + '</span>' } },
                { data: "send_after" },
            ],
            filterBy: [ 1, 2, 3, 4, 5 ],
            buttonClick: (evt) => {
                switch (evt.role) {
                    case "create":
                        self.form = {};
                        self.method = "POST";
                        self.errors = [];
                        $(e.formDialog).modal("show");
                        break;
                    case "update":
                        self.form = {};
                        self.method = "PUT";
                        self.errors = [];
                        if(evt.data.status == 0){
                            $(e.formEditDialog).modal("show");
                        }
                        if(evt.data.send_after){
                            this.set_schedule = "set"
                            self.form.send_after = moment(evt.data.send_after).format().slice(0,16);
                            self.datetime_min = moment(evt.data.send_after).format().slice(0,16);
                        }
                        this.form.id = evt.data.id;
                        this.form.image = evt.data.image;
                        this.form.title = evt.data.title;
                        this.form.message = evt.data.body;
                        this.showEditCheckedTag = evt.data.topic;
                        break;
                
                    default:
                        break;
                }
            },
			initComplete: function () {
				$('.loading-overlay').removeClass('show');
            },
        });
    }
}
</script>
<style>
    .modal .modal-dialog .modal-header {
        position: relative;
    }
    .modal .modal-dialog .modal-header .modal-title {
        position: relative;
    }
    .modal .modal-dialog .modal-header .close {
        position: absolute;
        right: 1rem;
    }
    .button_img{
        background: var(--background);
        /* cursor:pointer; */
        margin-right: auto;
        background-position: right ;
        background-size: 30px 30px;
    }
</style>